<script>
import { mapState } from 'vuex'

import ge2gjnlService from '@/services/entry/ge2gjnl.js'
import { toastMixins } from '@/mixins'

import MenuBar from './components/MenuBar.vue'
import HeaderForm from '../components/HeaderForm.vue'
import DetailForm from '../components/DetailForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, DetailForm, HeaderForm },
  data() {
    return {
      isLoading: false,
      templateId: null,
      header: {},
      details: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      stateConstantsDefaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  created() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.templateId = this.$route.params.templateId
      await this.fetchJournalDocumentTemplateById()
    },
    async fetchJournalDocumentTemplateById() {
      this.isLoading = true
      try {
        const res = await ge2gjnlService.getJournalDocumentTemplateById(
          this.templateId
        )
        const { header, details } = res.data.data
        this.header = header
        this.details = details
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    deleteConfirmedHandler() {
      setTimeout(() => {
        this.$router.replace('/entry/ge2gjnl/journal-document-templates')
      }, this.stateConstantsDefaultDelayMs)
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid="lg">
      <!-- แถบ menu ด้านบน -->
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
          <span class="text-xxl text-white" style="text-shadow: 2px 2px grey;">
            รายละเอียดต้นแบบป้อนรายการ
          </span>
        </b-col>
        <b-col
          class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
        >
          <menu-bar
            :headerProp="header"
            @deleteConfirmed="deleteConfirmedHandler"
          ></menu-bar>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- แถบแสดงผลข้อมูล -->
      <b-row class="my-4">
        <b-col cols="12">
          <header-form
            :operationProp="'DETAIL'"
            :headerProp="header"
            :isLoadingProp="isLoading"
          ></header-form>
        </b-col>
      </b-row>

      <!-- แถบตาราง -->
      <b-row>
        <b-col cols="12">
          <detail-form
            :operationProp="'DETAIL'"
            :detailsProp="details"
          ></detail-form>
        </b-col>
      </b-row>
    </b-container>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
