<script>
import ge2gjnlService from '@/services/entry/ge2gjnl.js'

export default {
  props: {
    templateSelectedProp: Object
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    cancelButtonClicked() {
      this.$emit('closeModal', { type: 'CLOSE' })
    },
    async confirmButtonClicked() {
      const templateId = this.templateSelectedProp.id
      this.isLoading = true

      try {
        await ge2gjnlService.deleteJournalDocumentTemplateById(templateId)
        this.$emit('closeModal', {
          type: 'CLOSE_WITH_SUCCESS',
          message: 'ลบต้นแบบป้อนรายการสำเร็จ'
        })
      } catch (err) {
        console.log('confirmButtonClicked error')
        this.$emit('closeModal', {
          type: 'DISPLAY_ERROR',
          message: this.getErrorMessage(err.response.data.thMessage)
        })
      }

      this.isLoading = false
    },
    getErrorMessage(message) {
      switch (message) {
        case 'journal_document_template_not_found':
          return 'ไม่พบต้นแบบป้อนรายการนี้'
      }
    }
  }
}
</script>

<template>
  <div>
    <!-- template has selected -->
    <div v-if="templateSelectedProp">
      <b-row class="mb-5" align-v="center">
        <!-- tash icon -->
        <b-col cols="3">
          <b-icon
            class="icon-style"
            icon="trash-fill"
            variant="danger"
          ></b-icon>
        </b-col>

        <!-- contents -->
        <b-col cols="9">
          <h5>
            {{ templateSelectedProp.templateName }}
          </h5>
          <div class="mt-3">
            <span>ท่านยืนยันที่จะลบต้นแบบป้อนรายการนี้ใช่หรือไม่</span>
          </div>
        </b-col>
      </b-row>

      <!-- hover line -->
      <div>
        <hr />
      </div>

      <!-- buttons -->
      <b-row align-h="end">
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="outline-danger"
            @click="cancelButtonClicked()"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-button
            class="w-100"
            variant="danger"
            @click="confirmButtonClicked()"
            :disabled="isLoading"
          >
            <b-spinner v-if="isLoading" variant="light" small></b-spinner>
            <span v-else>ยืนยัน</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- no template selected -->
    <div v-else>
      <span>กรุณาเลือกต้นแบบป้อนรายการที่ต้องการจะลบ</span>
    </div>
  </div>
</template>

<style scoped>
.icon-style {
  width: 100%;
  height: 100%;
}
</style>
