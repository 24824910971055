<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'

import DeleteModal from '../../components/DeleteModal.vue'

export default {
  mixins: [toastMixins],
  components: {
    DeleteModal
  },
  props: {
    headerProp: Object
  },
  data() {
    return {
      buttonClass:
        'shadow m-1 d-flex justify-content-center align-items-center',
      iconClass: 'mr-2',
      modalShow: false,
      modalCurrent: null,
      modalThemeColor: null,
      modalTitle: null,
      exportPdfClicked: false,
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    buttonClicked(operation) {
      switch (operation) {
        case 'DUPLICATE':
          this.$router.push(
            `/entry/ge2gjnl/journal-document-templates/duplicate/${this.headerProp.id}`
          )
          break
        case 'UPDATE':
          if (this.headerProp) {
            this.$router.push(
              `/entry/ge2gjnl/journal-document-templates/edit/${this.headerProp.id}`
            )
          }
          break
        case 'DELETE':
          this.modalCurrent = 'delete-modal'
          this.modalThemeColor = 'danger'
          this.modalTitle = 'ลบต้นแบบป้อนรายการเดิม'
          this.modalShow = true
          break
        case 'EXIT':
          this.$router.back()
          break
      }
    },
    closeModalHandler(event) {
      switch (event.type) {
        case 'CLOSE':
          this.modalShow = false
          break
        case 'CLOSE_WITH_SUCCESS':
          this.mxDisplaySuccessMessage(event.message)
          this.modalShow = false
          this.$emit('deleteConfirmed')
          break
        case 'DISPLAY_ERROR':
          this.errMsg = event.message
          break
        default:
          this.modalShow = false
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-content-center">
      <!-- DUPLICATE -->
      <div v-if="user && user.permission.entryJournalTemplate.canAddNew">
        <b-button
          :class="buttonClass"
          variant="success"
          @click="buttonClicked('DUPLICATE')"
          v-b-tooltip.hover.bottom.v-success="'ทำสำเนาต้นแบบป้อนรายการนี้'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="plus"></b-icon>
          <span class="text-sm">สำเนา</span>
        </b-button>
      </div>

      <!-- UPDATE -->
      <div v-if="user && user.permission.entryJournalTemplate.canUpdate">
        <b-button
          :class="buttonClass"
          variant="primary"
          @click="buttonClicked('UPDATE')"
          v-b-tooltip.hover.bottom.v-primary="'แก้ไขต้นแบบป้อนรายการนี้'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="pencil-square"></b-icon>
          <span class="text-sm">แก้ไข</span>
        </b-button>
      </div>

      <!-- DELETE -->
      <div v-if="user && user.permission.entryJournalTemplate.canDelete">
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('DELETE')"
          v-b-tooltip.hover.bottom.v-danger="'ลบต้นแบบป้อนรายการนี้'"
        >
          <b-icon :class="`${iconClass} text-sm`" icon="trash"></b-icon>
          <span class="text-sm">ลบ</span>
        </b-button>
      </div>

      <!-- EXIT -->
      <div>
        <b-button
          :class="buttonClass"
          variant="danger"
          @click="buttonClicked('EXIT')"
          v-b-tooltip.hover.bottom.v-danger="'ออกจากหน้านี้'"
        >
          <b-icon
            :class="`${iconClass} text-sm`"
            icon="box-arrow-right"
          ></b-icon>
          <span class="text-sm">ออก</span>
        </b-button>
      </div>

      <!-- MODAL DISPLAY -->
      <b-modal
        v-model="modalShow"
        :title="modalTitle"
        :header-bg-variant="modalThemeColor"
        header-text-variant="light"
        :hide-footer="true"
        :size="'md'"
      >
        <component
          :is="modalCurrent"
          :templateSelectedProp="headerProp"
          @closeModal="closeModalHandler"
        ></component>
      </b-modal>

      <error-modal
        :displayProp="!!errMsg"
        :errorMessageProp="errMsg"
        @modalClosed="errMsg = null"
      ></error-modal>
    </div>
  </div>
</template>

<style scoped>
.tooltip {
  background-color: transparent;
  border-color: transparent;
}
</style>
